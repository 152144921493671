<template>
  <div
    @mousedown="dragElement"
    id="simpleKeyboardWrapper" class="noselect">
    <span>{{ input }}</span>
    <div id="moveByThis">Catch here to move</div>
    <div :class="keyboardClass"/>
  </div>
</template>

<script>
import Vue from 'vue';
import Keyboard from "simple-keyboard";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default Vue.extend({
  name: "VirtualKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    }
  },
  data() {
    return {
      keyboard: null,
      pos1: null,
      pos2: null,
      pos3: null,
      pos4: null,
      elmnt: null,
      configureBoard: false,
    }
  },
  async mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress
    })
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input)
      console.log(input)
    }
  },
  computed: {
    ...mapState({
      virtualKeyboardValues: (state) => state.virtualKeyboardValues,
      virtualKeyboardRelatedId: (state) => state.virtualKeyboardRelatedId,
    })
  },
  methods: {
    onChange(input) {
    },
    onKeyPress(button) {
      let input = '';

      if (button === '{bksp}'){
        input = this.virtualKeyboardValues.slice(0, -1)
      }else if(button === '{space}'){
        input = this.virtualKeyboardValues + ' '
      } else {
        input = this.virtualKeyboardValues + button
      }

      this.$store.dispatch('setKeyboardInput', { input })

      /**
       * handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    },
    dragElement() {
      this.elmnt = document.getElementById('simpleKeyboardWrapper');

      if (document.getElementById("moveByThis")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById("moveByThis").onmousedown = this.dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        this.elmnt.onmousedown = this.dragMouseDown;
      }
    },
    dragMouseDown(e) {
      e = e || window.event;
      // get the mouse cursor position at startup:
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      document.onmouseup = this.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = this.elementDrag;
    },
    elementDrag(e) {
      e = e || window.event;
      // calculate the new cursor position:
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      // set the element's new position:
      this.elmnt.style.top = (this.elmnt.offsetTop - this.pos2) + "px";
      this.elmnt.style.left = (this.elmnt.offsetLeft - this.pos1) + "px";
    },
    closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
})
</script>

<style scoped>

.simple-keyboard {
  cursor: pointer;
  background-color: #333333;
}

#simpleKeyboardWrapper {
  max-width: 850px;
  width: 80%;
  height: 260px;
  background-color: #333333;
  border-radius: 5px;
  position: fixed;
  bottom: 3%;
  left: 20%;
  z-index: 1000;
  cursor: move;
}

#moveByThis {
  display: block;
  height: 30px;
  text-align: center;
  line-height: 2em;
  color: white;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
